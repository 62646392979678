<template>
  <div style="width: 142px; text-align: center; margin-right: 48px">
    <div class="box-container" :style="boxContainerStyle">
      <div class="box" ref="echarts"></div>
    </div>
    <div class="text-box">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "liquidBallCom",
  props: {
    value: {
      require: true,
      type: Number,
    },
    text: {
      require: true,
      type: String,
    },
    color1: {
      require: true,
      type: String,
    },
    color2: {
      require: true,
      type: String,
    },
    render: {
      type: Boolean,
      default: false
    }
  },
  watch:{
    render(val){
      if(val){
        this.init()
      }
    }
  },
  data() {
    return {
      boxContainerStyle: {
        backgroundImage: `linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, ${this.color1}, ${this.color2})`,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      var myChart = this.$echarts.init(this.$refs.echarts);

      var value = (this.value / 100).toFixed(2);
      var value1 = 0.75;

      var option = {
        title: [
          {
            show: false,
          },
        ],
        series: [
          {
            type: "liquidFill",
            radius: "100%",
            center: ["50%", "50%"],
            waveAnimation: false, //静止的波浪
            //   shape: "roundRect",
            color: [
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: this.color1,
                  },
                  {
                    offset: 1,
                    color: this.color2,
                  },
                ],
                globalCoord: false,
              },
            ],
            data: [value, value], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 0,
              color: "rgb(220,229,249)",
            },
            label: {
              normal: {
                textStyle: {
                  fontSize: 36,
                  color: "#fff",
                },
              },
            },
            outline: {
              show: false,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-container {
  width: 134px;
  height: 134px;
  line-height: 134px;
  text-align: center;
  padding: 6px;
  border: 2px solid transparent;
  border-radius: 100px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to bottom, #fff, #fff),
    linear-gradient(to bottom, rgba(6, 118, 255, 1), rgba(28, 212, 251, 1));
}
.box {
  width: 134px;
  height: 134px;
}
.text-box {
  margin-top: 24px;
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333333;
}
</style>
