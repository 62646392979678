<template>
  <div class="checkRecord-box">
    <div style="background-color: #fff; border-radius: 6px">
      <div style="display: flex; border-bottom: 1px solid #f2f2f2">
        <div style="height: 20px; margin: 18px 8px 14px 18px">
          <img src="@/assets/icon_instrument_overview.svg" />
        </div>
        <div
          style="
            margin-top: 18px;
            height: 20px;
            font-size: 20px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 500;
            color: #000000;
            line-height: 20px;
          "
        >
          仪器耗材配备概览
        </div>
      </div>
      <!-- 左侧仪器耗材配备详情 -->
      <div class="topBoxLeft">
        <div class="item-one">
          <span style="font-weight: 500; font-size: 20px"
            >仪器耗材配备详情</span
          >
          <div class="item-one-img">
            <img src="@/assets/icon_popup_tip.svg" />
          </div>
        </div>
        <!-- 概述详情 -->
        <div v-loading="loadForm" class="materialDetailBox">
          <liquidBallCom
            :value="materialRate"
            text="仪器耗材配齐率"
            color1="#0676FF"
            color2="#1CD4FB"
            :render="render"
          ></liquidBallCom>
          <liquidBallCom
            :value="dangerRate"
            text="危化品占比"
            color1="#FFA0A0"
            color2="#F71C1C"
            :render="render"
          ></liquidBallCom>
          <liquidBallCom
            :value="lossBrokenRate"
            text="报损报失率"
            color1="#FAD961"
            color2="#F76B1C"
            :render="render"
          ></liquidBallCom>
          <liquidBallCom
            :value="materialUsedRate"
            text="仪器耗材使用率"
            color1="#B4EC51"
            color2="#429321"
            :render="render"
          ></liquidBallCom>
        </div>
      </div>
    </div>

    <div style="background-color: #fff; border-radius: 6px; margin-top: 20px">
      <el-row>
        <div
          style="
            display: flex;
            padding-bottom: 16px;
            font-size: 18px;
            height: 20px;
            line-height: 20px;
            font-weight: 700;
            border-bottom: 1px solid #eee;
            margin: 18px 0 14px 0;
            position: relative;
          "
        >
          <div
            style="
              height: 20px;
              line-height: 20px;
              margin-right: 8px;
              margin-left: 18px;
            "
          >
            <img src="@/assets/icon_instrument_detail.svg" />
          </div>
          <div
            style="
              font-size: 20px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #000000;
            "
          >
            仪器耗材配备明细
          </div>
          <div
            style="position: absolute; top: 0; right: 18px; cursor: pointer"
            @click="
              () => {
                detailMode = !detailMode;
              }
            "
          >
            <div>
              <div style="display: flex">
                <div style="font-size: 18px; font-weight: 500; color: #0676ff">
                  {{ detailMode ? "图表模式" : "表格模式" }}
                </div>
                <div
                  style="
                    height: 16px;
                    width: 16px;
                    line-height: 20px;
                    margin-left: 4px;
                    margin-top: 2px;
                  "
                >
                  <img
                    v-if="detailMode"
                    src="@/assets/icon_instrument_chart.svg"
                  />
                  <img v-else src="@/assets/icon_instrument_tabulation.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-row>
      <div style="padding: 10px 20px 0.1px 20px; margin-bottom: 20px">
        <el-row v-show="detailMode">
          <el-table
            v-loading="loadTable"
            :data="inventoryDataList"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#F2F2F2',
              color: '#000000',
            }"
            :cell-style="{ 'text-align': 'center' }"
            height="400"
          >
            <el-table-column label="排行" prop="rank"></el-table-column>
            <el-table-column
              label="学校"
              prop="sysOrgSchoolName"
            ></el-table-column>
            <el-table-column label="所属教育局" prop="sysOrgAreaName">
              <template slot-scope="scope">
                {{ scope.row.sysOrgAreaName ? scope.row.sysOrgAreaName : "/" }}
              </template>
            </el-table-column>
            <el-table-column label="仪器耗材配齐率" prop="materialRate">
              <template slot-scope="scope">
                {{ scope.row.materialRate ? scope.row.materialRate * 100 : 0 }}%
              </template>
            </el-table-column>
            <el-table-column label="实验室数量" prop="sysOrgSchoolRoomCount">
              <template slot-scope="scope">
                {{
                  scope.row.sysOrgSchoolRoomCount
                    ? scope.row.sysOrgSchoolRoomCount
                    : 0
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="最近一次盘点时间"
              prop="stockInventoryTime"
              :formatter="customFormatter"
            ></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <span
                  style="
                    color: #0676ff;
                    cursor: pointer;
                    margin-right: 5px;
                    padding-right: 5px;
                    border-right: 2px solid #ccc;
                  "
                  @click="handleCheckDetailBtn(scope.row)"
                  >查看</span
                >
                <span style="color: #0676ff; cursor: pointer"
                  >导出配备详情</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <!-- <el-row style="margin: 20px 0" v-show="detailMode">
          <el-pagination
            @size-change="handleSizeChangeTableMsgPage"
            @current-change="handleCurrentChangeTableMsgPage"
            :current-page="tablePageMsg.pageIndex"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="tablePageMsg.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tablePageMsg.pageTotal"
          ></el-pagination>
        </el-row> -->
        <el-row v-show="!detailMode" style="height: 370px">
          <div id="materialEquipmentEchartsID"></div>
        </el-row>
      </div>
    </div>

    <!-- 查看详情弹框 -->
    <el-dialog
      :visible.sync="detailDialogVisible"
      title="仪器耗材配备详情"
      top="3%"
    >
      <div class="titleBox">实验室配备详情</div>
      <div class="materialDetailBox dataBox" style="width: 230px">
        <el-tooltip
          class="item"
          effect="dark"
          content="统计该校所有功能室为“实验室”的数量"
          placement="top"
        >
          <div class="materialDetailBox-item">
            <div class="item-one">
              <div>实验室数量</div>
              <div class="item-one-img">
                <img src="@/assets/noticePic.svg" />
              </div>
            </div>
            <div class="item-two">{{ schoolDetail.sysOrgSchoolRoomCount }}</div>
          </div>
        </el-tooltip>
      </div>
      <el-table
        :data="roomDataList"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        height="300"
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column
          label="实验室名称"
          prop="sysOrgSchoolRoomName"
        ></el-table-column>
        <el-table-column
          label="所属科目"
          prop="sysDicSubjectName"
        ></el-table-column>
        <el-table-column label="实际数量" prop="actualNum"></el-table-column>
      </el-table>
      <div class="titleBox" style="margin-top: 20px">仪器耗材配备详情</div>
      <div class="materialDetailBox dataBox" style="width: 100%">
        <el-tooltip
          class="item"
          effect="dark"
          content="按标准统计该校仪器耗材配齐率"
          placement="top"
        >
          <div class="materialDetailBox-item">
            <div class="item-one">
              <div>仪器耗材配齐率</div>
              <div class="item-one-img">
                <img src="@/assets/noticePic.svg" />
              </div>
            </div>
            <div class="item-two">
              {{ Math.floor(schoolDetail.materialRate * 100) }}%
            </div>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="按危化品种类数/该校登记的仪器耗材种类数，统计危化品占比比例"
          placement="top"
        >
          <div class="materialDetailBox-item">
            <div class="item-one">
              <div>危化品占比</div>
              <div class="item-one-img">
                <img src="@/assets/noticePic.svg" />
              </div>
            </div>
            <div class="item-two">
              {{ Math.floor(schoolDetail.dangerRate * 100) }}%
            </div>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="按报损报失种类数/该校登记的仪器耗材种类数，统计报损报失种类占比比例"
          placement="top"
        >
          <div class="materialDetailBox-item">
            <div class="item-one">
              <div>报损报失率</div>
              <div class="item-one-img">
                <img src="@/assets/noticePic.svg" />
              </div>
            </div>
            <div class="item-two">
              {{ Math.floor((schoolDetail.lossRate + schoolDetail.dangerRate) * 50) }}%
            </div>
          </div>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="按课程中关联实验仪器耗材种类数/该校登记的仪器耗材种类数，统计使用仪器耗材种类占比比例"
          placement="top"
        >
          <div class="materialDetailBox-item">
            <div class="item-one">
              <div>仪器耗材使用率</div>
              <div class="item-one-img">
                <img src="@/assets/noticePic.svg" />
              </div>
            </div>
            <div class="item-two">
              {{ Math.floor(schoolDetail.materialUsedRate * 100) }}%
            </div>
          </div>
        </el-tooltip>
      </div>
      <div style="text-align: center; margin-top: 20px">
        <el-button
          @click="
            () => {
              detailDialogVisible = false;
            }
          "
          >返回</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import liquidBallCom from "@/components/liquidBallCom.vue";
import {
  getAllSchoolSummaryApi,
  getSchoolLibDetailApi,
} from "@/api/materialManage/drugEquipment";
import * as echarts from "echarts";
import { isEmpty, formatDate } from "@/utils/util";
export default {
  name: "checkRecord",
  components: {
    liquidBallCom,
  },
  data() {
    return {
      tableCondition: {
        inventoryStatus: "",
        materialCodeName: "",
        taskName: "",
        school: "",
      },
      tablePageMsg: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 35,
      },
      inventoryDataList: [],
      activeTitle: ["showTitleData"],
      detailMode: 1,
      materialEquipmentEcharts: null,

      detailDialogVisible: false,
      roomDataList: [],
      materialRate: 0,
      materialUsedRate: 0,
      lossBrokenRate: 0,
      dangerRate: 0,
      render: false,

      schoolDetail: {},

      loadTable: true,
      loadForm: true
    };
  },

  created() {
    // this.fetchInventoryStockInfoList();
    this.fetchSchoolSummary();
  },
  mounted() {
    document.querySelector("body").setAttribute("style", "background:#F5F6FA");
    this.getMaterialEquipmentEchartsFunc();
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },

  methods: {
    // 获取学校概况
    fetchSchoolSummary() {
      getAllSchoolSummaryApi().then((res) => {
        if (res.success) {
          this.inventoryDataList = res.data.eqptStockSummaryList.map(
            (item, i) => ({
              ...item,
              rank: i + 1,
            })
          );
          this.materialRate = res.data.materialRate
            ? res.data.materialRate * 100
            : 0;
          this.materialUsedRate = res.data.materialUsedRate
            ? res.data.materialUsedRate * 100
            : 0;
          this.dangerRate = res.data.dangerRate ? res.data.dangerRate * 100 : 0;
          this.lossBrokenRate = (res.data.brokenRate + res.data.lossRate) * 50;
          this.render = true;
          this.getMaterialEquipmentEchartsFunc();
          this.loadForm = false
          this.loadTable = false
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleCurrentChangeTableMsgPage(val) {
      // this.tablePageMsg.pageIndex = val;
      // this.fetchInventoryStockInfoList();
    },
    handleSizeChangeTableMsgPage(val) {
      // this.tablePageMsg.pageIndex = 1;
      // this.tablePageMsg.pageSize = val;
      // this.fetchInventoryStockInfoList();
    },
    // 查看详情按钮点击
    handleCheckDetailBtn(row) {
      // this.detailDialogVisible = true;
      getSchoolLibDetailApi(row.sysOrgSchoolId).then((res) => {
        if (res.success) {
          this.schoolDetail = row;
          this.roomDataList = res.data;
          this.detailDialogVisible = true;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 仪器耗材配备明细表格
    getMaterialEquipmentEchartsFunc() {
      this.materialEquipmentEcharts = echarts.init(
        document.getElementById("materialEquipmentEchartsID")
      );
      let option = {
        color: ["#1981FF"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#1981FF",
            },
          },
          formatter: (params) => {
            let template = "";
            params.forEach((item, index) => {
              if (index == 0) {
                template = item.axisValue + "<br>";
              }
              if (item.seriesType === "line") {
                item.value += "%";
              }
              template =
                template +
                item.marker +
                item.seriesName +
                " : " +
                item.value +
                "<br>";
            });
            return template;
          },
        },
        grid: {
          left: "0%",
          right: "2%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.inventoryDataList.map((it) => it.sysOrgSchoolName),
          },
        ],
        yAxis: [
          {
            type: "value",
            min: 0,
            max: 100,
            axisLabel: {
              formatter: "{value}%",
            },
          },
        ],
        series: [
          {
            name: "配齐率",
            type: "line",
            stack: "Total",
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#99C7FF",
                },
                {
                  offset: 1,
                  color: "#EFF6FF",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: this.inventoryDataList.map((it) => it.materialRate * 100),
          },
        ],
      };

      this.materialEquipmentEcharts.setOption(option);
    },
    customFormatter(row, column) {
      switch (column.property) {
        case "stockInventoryTime":
          if (isEmpty(row.stockInventoryTime)) {
            return "/";
          }
          return formatDate(
            new Date(row.stockInventoryTime),
            "yyyy/MM/dd hh:mm"
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.materialDetailBox {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  margin-left: 20px;
  padding-bottom: 60px;
  &.dataBox {
    margin-top: 20px;
    margin-left: 0;
    width: 80%;
    margin-bottom: -30px;
  }
}
.item-two {
  font-size: 36px;
  text-align: center;
}
::v-deep .collapse-box {
  .el-collapse-item__header {
    padding-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
  }
}
.item-one {
  display: flex;
  margin-bottom: 20px;
  margin-top: 19px;
  margin-left: 18px;
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #000000;
  span {
    width: 160px;
    height: 20px;
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #000000;
    line-height: 20px;
  }
}
.item-one-img {
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-left: 6px;
  img {
    width: 20px;
    height: 20px;
  }
}
.topBoxRight {
  margin-left: 30px;
}
#materialEquipmentEchartsID {
  width: 83vw;
  height: 300px;
}
.titleBox {
  padding-left: 10px;
  border-left: 5px solid #00f;
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  margin-bottom: 20px;
}
</style>
