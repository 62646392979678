import { request } from "@/utils/request.js";

// 获取【全部学校】概览
export function getAllSchoolSummaryApi() {
  return request({
    url: `/eqpt/stock/consume/material/all/school/summary`,
    method: "post",
  });
}

// 获取学校【实验室详情】
export function getSchoolLibDetailApi(sysOrgSchoolId) {
  return request({
    url: `/eqpt/stock/consume/material/school/room/detail/`+sysOrgSchoolId,
    method: "post",
    params: {
      
    },
  });
}
